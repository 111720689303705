/** @jsx jsx */
import { graphql } from 'gatsby'
import { jsx } from 'theme-ui'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import PersonBio from 'src/components/personBio'

const About = ({ data }) => {
  return (
    <Layout>
      <SEO title="О проекте" />
      <div
        sx={{ variant: 'boxes.container', maxWidth: 'content', pb: 4, pt: 6 }}
      >
        <h1>О проекте</h1>
        <p>
          <strong>Перемена.медиа</strong> – первое медиа в Казахстане для
          обсуждения трендов современного образования и воспитания. Это сайт для
          родителей детей 7-18 лет и экспертного сообщества – педагогов,
          психологов, НПО в сфере инклюзивного образования, образовательных
          стартапов.
        </p>
        <h2>Нам важно</h2>
        <ul>
          <li>
            Объединить вокруг ресурса родителей, педагогов, экспертов, бизнес и
            госсектор для обмена мнениями о будущем образования в Казахстане.
          </li>
          <li>Показать истории успеха талантливых казахстанских детей.</li>
          <li>Находить полезный образовательный опыт и рассказывать о нём.</li>
        </ul>
        <h2>О чём мы пишем</h2>
        <ul>
          <li> Люди, которые внедряют новые образовательные технологии.</li>
          <li>Экспертные мнения психологов и педагогов.</li>
          <li>Проектное образование. Школы будущего. Новые профессии.</li>
          <li>
            Лайфхаки и советы для продуктивности, самообразования и правильного
            использования технологий.
          </li>
          <li>
            Книги, музыка, кино, которые помогают родителям развивать у детей
            эмоциональный интеллект и эстетический вкус.
          </li>
        </ul>
        <h2>Достижения Перемена.медиа</h2>
        <p>
          Мы запустили издание в мае 2017 года, но уже успели выиграть многие
          специализированные конкурсы.
        </p>
        <ul>
          <li>
            Призёр Национальной интернет-премии за лучшие сайты «Award.kz» в
            категории «Детское образование, воспитание и наука»
          </li>
          <li>
            Победитель Республиканского Конкурса для СМИ «ЗачОт» на лучшее
            освещение тем образования в Казахстане
          </li>
          <li>
            Победитель конкурса UNICEF Kazakhstan «Мы ищем суперпапу» на
            освещение роли отцов в воспитании детей с первых дней жизни
          </li>
          <li>
            Победитель Республиканского конкурса для журналистов «Новая школа
            нового времени» (НИШ) на информационную поддержку казахстанской
            школы 21-го века
          </li>
          <li>
            Победитель конкурса КФ «Болашак» на лучшие материалы по освещению
            инклюзивного образования «Каждый ребёнок достоин школы»
          </li>
        </ul>
        <h2>Контакты</h2>
        <p>
          По вопросам сотрудничества и рекламы пишите на{' '}
          <a href="mailto:ask@peremena.media">ask@peremena.media</a>
        </p>
        <PersonBio
          name="Айжан Мадиходжаева"
          role="Главный редактор"
          phone="+7 701 721 11 17"
          location="г.Астана"
          image={data.aizhan.childImageSharp.fluid}
        />
        <PersonBio
          name="Дия Батенова"
          role="Директор по развитию"
          phone="+7 707 853 91 08"
          location="г.Алматы"
          image={data.diya.childImageSharp.fluid}
        />
        <PersonBio
          name="Ильдар Тапалов"
          role="Креативный директор"
          phone="+7 707 853 91 18"
          location="г.Алматы"
          image={data.ildar.childImageSharp.fluid}
        />
      </div>
    </Layout>
  )
}

export default About

export const query = graphql`
  query {
    aizhan: file(relativePath: { eq: "aizhan.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    diya: file(relativePath: { eq: "diya.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ildar: file(relativePath: { eq: "ildar.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
