/** @jsx jsx */
import { jsx } from 'theme-ui'
import Img from 'gatsby-image'

const PersonBio = ({ name, role, description, phone, location, image }) => (
  <div
    sx={{
      display: 'flex',
      flexDirection: ['column', 'row'],
      alignItems: ['center', 'flex-start'],
      justifyContent: ['center', 'flex-start'],
      mb: [3, 5],
    }}
  >
    <Img
      fluid={image}
      sx={{
        maxWidth: '200px',
        width: '100%',
        height: 'auto',
      }}
    />
    <div sx={{ p: 3, pl: [3, 5], textAlign: ['center', 'left'] }}>
      <h3 sx={{ mb: 2 }}>{name}</h3>
      <p sx={{ mb: 1 }}>{role}</p>
      {description && <p sx={{ mb: 1 }}>{description}</p>}
      {/* <p sx={{ mb: 1 }}>{phone}</p> */}
      {location && <p sx={{ mb: 1 }}>{location}</p>}
    </div>
  </div>
)

export default PersonBio
